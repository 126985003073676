var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { "max-width": "500px", margin: "40px auto 0" } },
        [
          _c("a-alert", {
            staticStyle: { "margin-bottom": "24px" },
            attrs: {
              closable: true,
              message: "确认转账后，资金将直接打入对方账户，无法退回。",
            },
          }),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: "付款账户",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
              },
            },
            [_vm._v("\n      ant-design@alipay.com\n    ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: "收款账户",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
              },
            },
            [_vm._v("\n      test@example.com\n    ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: "收款人姓名",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
              },
            },
            [_vm._v("\n      Alex\n    ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: "转账金额",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
              },
            },
            [_vm._v("\n      ￥ 5,000.00\n    ")]
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 19, offset: 5 } } },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.nextStep },
                },
                [_vm._v("提交")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.prevStep },
                },
                [_vm._v("上一步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }